/* eslint-disable import/extensions */

// Services Img Imports
import Web from '../assets/images/Services/drafting.jpg';
import Mobile from '../assets/images/Services/consultant.png';
import UIUX from '../assets/images/Services/estimate.jpg';
import construction from '../assets/images/Services/construction.jpg';
import engineering from '../assets/images/Services/engineering.jpg';
import signage from '../assets/images/Services/signage.jpg';

// Portfolio Img Imports
import Recruiting from '../assets/images/Portfolio/2.jpg';
import Stream from '../assets/images/Portfolio/1.jpg';
import Freelance from '../assets/images/Portfolio/2b.jpg';
import Aura from '../assets/images/Portfolio/3.jpg';
import Surtido from '../assets/images/Portfolio/3b.jpg';
import ManagementApp from '../assets/images/Portfolio/8.jpg';

// Advantages
import Communicative from '../assets/images/Advantages/Communicative.png';
import Collaborative from '../assets/images/Advantages/Collaborative.png';
import Management from '../assets/images/Advantages/Management.png';
import Favorite from '../assets/images/Advantages/Favorite.png';

// Testimonials
import Sasha from '../assets/images/Testimonials/Sasha.jpg';
import Reiner from '../assets/images/Testimonials/Reiner.jpg';
import Kruger from '../assets/images/Testimonials/Kruger.jpg';

// TeamMembers
import CEO from '../assets/images/TeamMembers/CEO.jpg';
import HRD from '../assets/images/TeamMembers/HRD.jpg';
import Finance from '../assets/images/TeamMembers/Finance.jpg';
import ProjectManager from '../assets/images/TeamMembers/Project-manager.jpg';
import Frontend1 from '../assets/images/TeamMembers/Frontend1.jpg';
import Frontend2 from '../assets/images/TeamMembers/Frontend2.jpg';
import Backend1 from '../assets/images/TeamMembers/Backend1.jpg';
import Backend2 from '../assets/images/TeamMembers/Backend2.jpg';
import Mobile1 from '../assets/images/TeamMembers/Mobile1.jpg';
import Mobile2 from '../assets/images/TeamMembers/Mobile2.jpg';
import UIUX1 from '../assets/images/TeamMembers/UIUX1.jpg';
import UIUX2 from '../assets/images/TeamMembers/UIUX2.jpg';

export const Services = [
  {
    title: 'Design and Drafting / Drawing',
    imageUrl: Web,
    animation: 'left',
  },
  {
    title: 'Design Consultancy',
    imageUrl: Mobile,
    animation: 'up',
  },
  {
    title: 'Material Estimation and Surveying',
    imageUrl: UIUX,
    animation: 'right',
  },
  {
    title: 'Construction (Design and Build)',
    imageUrl: construction,
    animation: 'left',
  },
  {
    title: 'Value-Engineering',
    imageUrl: engineering,
    animation: 'up',
  },
  {
    title: 'Signage Design and Installation',
    imageUrl: signage,
    animation: 'right',
  },
];

export const Portfolios = [
  {
    id: 'asd1293uasdads1',
    title: '"Escasa Residence" 3-storey residence',
    imageUrl: Recruiting,
    type: 'Modern Architectural Design',
    responsibility: [
      'Mobile Development',
      'UI/UX Design',
    ],
    credit: 'https://dribbble.com/shots/15164950-Recruiting-app',
  },
  {
    id: 'asd1293uhjkhkjh2',
    title: '"Ikon" Two-storey High-end super car bar',
    imageUrl: Stream,
    type: 'Modern design to showcase supercars',
    responsibility: [
      'Mobile Development',
      'UI/UX Design',
    ],
    credit: 'https://dribbble.com/shots/15276430-Stream',
  },
  {
    id: 'asd1293uvbvcbbd3',
    title: '"Rhino Head Office" Rhino Head Development Inc. Head Quarter',
    imageUrl: Freelance,
    type: 'Classical-contemporary and modern design architecture',
    responsibility: [
      'Mobile Development',
      'UI/UX Design',
    ],
    credit: 'https://dribbble.com/shots/15223131-Freelance-Mobile-App-Concept',
  },
  {
    id: 'asd1293ufgdfgs4',
    title: '"The Pod" Three-storey pod capsule hotel',
    imageUrl: Aura,
    type: 'A modern designed capsule hotel inspired by American Modern Design',
    responsibility: [
      'Web Development',
      'UI/UX Design',
    ],
    credit: 'https://dribbble.com/shots/15176338-Aura-Website-Main-Page',
  },
  {
    id: 'asd1293ulskmnb5',
    title: 'SM Savemore Renovation',
    imageUrl: Surtido,
    type: 'Renovation using modern architechtural finishes and materials.',
    responsibility: [
      'Web Development',
      'UI/UX Design',
    ],
    credit: 'https://dribbble.com/shots/15173118-Surtido-Rico',
  },
  {
    id: 'asd1293ulkmnbj6',
    title: 'King Fahd University - Petroleum and Mineral Business Park',
    imageUrl: ManagementApp,
    type: 'Modernized Residential, office towers and infrastructure scheme',
    responsibility: [
      'Web Development',
      'UI/UX Design',
    ],
    credit: 'https://dribbble.com/shots/15197890-Courses-Management-and-Promoting-Web-App',
  },
];

export const Advantages = [
  [{
    title: 'History',
    description: 'ARKICOOL RESIDENTIAL BUILDING CONSTRUCTION, led by Arch. Randy Barlizo Serra, provides comprehensive design-consultancy, construction, engineering, air-conditioning, and signage solutions, backed by years of expertise and successful projects.',
    imageUrl: Communicative,
  },
  {
    title: 'Vision',
    description: 'To procure projects at unique Conceptualization Design, applied Value-Engineering Construction, Competitive Pricing in Air-Conditioning and Signages Dealership, Sales, Installation and Maintenance, providing safe working conditions and deliver quality work within areasonable time frame with applied value- engineering.',
    imageUrl: Management,
  }],
  [{
    title: 'Mission',
    description: 'To be a respectable Design- Consultancy, Construction,Engineering, Signages and Air-Conditioning Dealer, Installer and Maintenance Provider delivering beyond expectation, always.',
    imageUrl: Collaborative,
  },
  {
    title: 'Objectives',
    description: "Our goal is to provide clients with a confident and assured experience on every project. By 2030, we aim to be a leading design-consultation, construction, signage, and air-conditioning service provider in the Philippines and internationally, while growing our profit by Php 100 million.",
    imageUrl: Favorite,
  }],
];
export const Testimonials = [
  {
    id: 1,
    name: 'Sasha Rose',
    company: 'Owner, Surveyor Corps',
    testimoni: 'Thanks for Racxstudio, you guys are the best! Keep up the great work!',
    imageUrl: Sasha,
  },
  {
    id: 2,
    name: 'Kruger Khan',
    company: 'Director, Shultan Oil',
    testimoni: 'I just wanted to let you know that it’s been great working with Racxstudio.',
    imageUrl: Kruger,
  },
  {
    id: 3,
    name: 'Reiner John',
    company: 'CEO, Marley CO',
    testimoni: 'Racxstudio is so great. Thank you so much for a job well done.',
    imageUrl: Reiner,
  },
];

export const TeamMembers = [
  {
    name: 'Rach David',
    position: 'CEO',
    imageUrl: CEO,
  },
  {
    name: 'Pauline Sydney',
    position: 'HRD',
    imageUrl: HRD,
  },
  {
    name: 'Granger Watterson',
    position: 'Finance',
    imageUrl: Finance,
  },
  {
    name: 'Tom Jimmy',
    position: 'Project Manager',
    imageUrl: ProjectManager,
  },
  {
    name: 'Jim Hendrix',
    position: 'Front-end Developer',
    imageUrl: Frontend1,
  },
  {
    name: 'Calvin Max',
    position: 'Front-end Developer',
    imageUrl: Frontend2,
  },
  {
    name: 'Hawkins Jim',
    position: 'Back-end Developer',
    imageUrl: Backend1,
  },
  {
    name: 'Don Bizaro',
    position: 'Back-end Developer',
    imageUrl: Backend2,
  },
  {
    name: 'Bill Markinson',
    position: 'Mobile Developer',
    imageUrl: Mobile1,
  },
  {
    name: 'Igor Kavarov',
    position: 'Mobile Developer',
    imageUrl: Mobile2,
  },
  {
    name: 'Freddie Curl',
    position: 'UI/UX Designer',
    imageUrl: UIUX2,
  },
  {
    name: 'Monica Lovegood',
    position: 'UI/UX Designer',
    imageUrl: UIUX1,
  },
];
